@import url(fonts.css);
@import url(variable.css);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

* {
  font-family: "LatoRegular", "LatoLight", "LatoBold", "LatoBlack", "LatoMedium",
    "LatoSemibold", "Lato", sans-serif;
}

* > i {
  font-weight: 100;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
ol {
  margin: 0;
  padding: 0;
}

ul,
li,
ol {
  list-style: none;
}

a,
a:hover {
  text-decoration: none;
}

input,
input:hover,
input:focus {
  outline: none;
  border: none;
}

.main-menu li > a {
  position: relative;
}

.main-menu li > a.active {
  position: relative;
  color: var(--secondary) !important;
  /* display: inline-block; */
}

.main-menu li > a::before {
  position: absolute;
  content: " ";
  width: 0;
  height: 2px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transition: all 0.3s ease;
  /* display: inline-block; */
  text-decoration: none;
}

.main-menu li > a.active::before {
  position: absolute;
  content: " ";
  width: 100%;
  max-width: 24px;
  background: var(--secondary) !important;
  height: 2px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transition: all 0.3s ease;
  /* display: inline-block; */
}

.main-menu li > a:hover::before,
.main-menu li > a.active::before {
  width: 100%;
  background: var(--secondary);
  color: var(--secondary);
}

.main-menu ul li:hover .dropdown-menu {
  display: block;
}

.cmn-sub-title {
  font-size: var(--fs-70);
  color: var(--sub-title-text);
  font-family: var(--font-latoblack);
}

.cmn-text {
  font-size: var(--fs-24);
  color: var(--gray-text);
  font-family: var(--font-semibold);
}

.site-footer .cmn-text {
  font-size: var(--fs-18);
  font-family: var(--font-semibold);
  color: var(--off-gray);
}

.link-text {
  font-size: var(--fs-18);
  color: var(--off-gray);
  font-family: var(--font-semibold);
}

.link-text:hover {
  color: var(--white);
}

.link-text:hover i {
  color: var(--white);
}

.cmn-color:hover {
  color: var(--white);
}

.footer-cmn-title {
  font-size: var(--fs-26);
  color: var(--secondary);
  font-family: var(--font-latoblack);
}

.video-title {
  font-size: var(--fs-24);
  color: var(--secondary);
  font-family: var(--font-bold);
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 10px 0 0 0;
}

/* .navbar-brand{
    padding: 0 0 0 150px;
} */
.custom-container {
  max-width: 1620px;
}

.cmn-sec-padding {
  padding: 100px 0;
}

.cmn-banner-title {
  font-size: var(--fs-70);
  font-family: var(--font-latoblack);
  color: var(--white);
  line-height: 56px;
  text-transform: uppercase;
}

.cmn-banner-text {
  font-size: var(--fs-50);
  color: var(--white);
  font-family: var(--font-medium);
}

/*header-start*/
.site-header {
  background: var(--white);
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 999;
  top: 0;
  left: 0;
  border-bottom: 1px solid #c0c0c0;
}

.custom-collapse .custom-item {
  padding: 0 60px 0 0;
  display: inline-block;
}

.custom-collapse .custom-link {
  color: var(--primary);
  font-size: var(--fs-20);
  font-family: var(--font-bold);
  display: inline-block;
  padding: 8px 0 !important;
}

.custom-collapse .contact-top-btn {
  background: var(--secondary);
  padding: 22px 30px;
}

.custom-collapse .ctc-top-text {
  color: var(--white) !important;
  font-size: var(--fs-26);
  font-family: var(--font-bold);
  padding: 0;
}

.custom-collapse .ctc-top-number {
  color: var(--white) !important;
  font-size: var(--fs-16);
  font-family: var(--font-bold);
  padding: 0;
  text-decoration: underline;
}

.site-header .navbar {
  padding: unset;
}

/*header-end*/

/*banner-section-start*/
.top-banner-section {
  padding: 100px 0 0 0;
  position: relative;
  width: 100%;
}

.banner-image {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  min-height: 778px;
}

.banner-bottom-box {
  background: var(--white);
  padding: 26px;
  position: absolute;
  content: "";
  top: 50px;
  max-width: 573px;
  border-radius: 20px;
}

.banner-btm-text {
  font-size: var(--fs-28);
  font-family: var(--font-medium);
}

.hero-item {
  position: relative;
  width: 100%;
  background-size: cover;
}

.top-banner-section .slick-dots {
  position: absolute;
  bottom: 5%;
  display: flex !important;
  right: 20px;
  width: 100%;
  justify-content: flex-end;
  height: 20px;
}

.top-banner-section .slick-dots button {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  display: block;
  padding: 0;
  border: none;
  background-color: var(--white);
  text-indent: -9999px;
}

.top-banner-section li.slick-active button {
  background-color: var(--green);
}

.top-banner-section li {
  margin: 0 0 0 10px;
}

/*banner-section-end*/

/*about-section-start*/
.about-sec {
  background: url("../img/Blueprint.png") no-repeat left center;
  width: 100%;
  position: relative;
}

.about-sec .about-right {
  padding: 70px 0 0 40px;
}

.about-left .cmn-text {
  padding: 10px 0 30px 0;
}

.dot {
  width: 16px;
  height: 16px;
  border-radius: 50px;
  background: var(--green);
  display: inline-block;
}

.read-more-link {
  color: var(--white);
  font-size: var(--fs-20);
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
  max-width: 200px;
  padding: 15px 30px;
  border-radius: 10px;
  background: var(--green);
  position: relative;
  transition: all 0.3s ease;
  border: 1px solid #4ac966;
}
.company-videos {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.company-videos .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.company-modalvideobox {
  height: 300px;
  width: 100%;
}

.about-sec .modal .btn-close {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  z-index: 999;
  background-color: var(--white);
}
/* .cmp-intro {
  display: flex;
  position: relative;
  background: url("../img/video-img.png") no-repeat center;
  background-size: cover;
  width: 100%;
  min-height: 150px;
  border-radius: 10px;
  justify-content: center;
}

.video-popup {
  display: flex;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.7);
  justify-content: center;
  align-items: center;
}

.video-popup-content {
  background-color: transparent;
  margin: auto;
  padding: 0px;
  border: 1px solid #888;
  width: 90%;
  max-width: 800px;
  position: relative;
} */

.close-popup-btn {
  position: absolute;
  top: -20px;
  right: -20px;
  width: 50px;
  height: 50px;
  background-color: #fff;
  border-radius: 50%;
  font-size: 40px;
  line-height: 50px;
  z-index: 999;
  font-weight: bold;
  color: #f70808;
  cursor: pointer;
}

.close-popup-btn:hover,
.close-popup-btn:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 aspect ratio */
  height: 0;
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-content {
  position: absolute;
  transform: translate(-50%, 15%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.cmp-intro .video-text {
  font-size: var(--fs-20);
  color: var(--white);
  font-family: var(--font-bold);
  padding: 20px 0 0 0;
}

.brochure-text {
  font-size: var(--fs-20);
  color: #ff2116;
  font-family: var(--font-bold);
  padding: 20px 0 0 0;
}

.cmp-brochure {
  width: 100%;
  border: 1px solid #ff2116;
  border-radius: 10px;
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.brochure-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-left .btn-container {
  padding: 40px 0 0 0;
}

.vision-sec {
  background: var(--off-white) url("../img/vision-img.png") no-repeat bottom
    center;
  width: 100%;
}

.vision-right {
  padding: 94px 0 204px 0;
  text-align: justify;
}

.mision-sec {
  background: url("../img/mission-img.png") no-repeat center;
  background-size: cover;
  width: 100%;
}

.mision-left {
  padding: 100px 0;
}

.mision-left .cmn-sub-title {
  color: var(--white);
  opacity: 0.6;
}

.mision-left .cmn-text {
  color: var(--white);
  text-align: justify;
}

.misionleft-content p:not(:last-child) {
  padding-bottom: 20px;
}

.core-value-sec {
  position: relative;
}

.core-value-sec::before {
  position: absolute;
  content: "";
  background: url("../img/core-value-img.png") no-repeat left center;
  width: 60%;
  height: 100%;
  top: 0;
  bottom: 0;
  background-size: cover;
}

.core-value-sec .cmn-sub-title {
  color: var(--white);
  opacity: 0.6;
}

.core-value-left {
  position: relative;
}

.core-value-box {
  position: relative;
}

.valuebox-content {
  background: linear-gradient(
    269.46deg,
    #f7f9fe 0.43%,
    rgba(247, 249, 254, 0.8) 99.53%
  );
  border-radius: 20px;
  padding: 30px;
}

.valuebox-content > h5 {
  padding-bottom: 20px;
}

.valuebox-content p:not(:last-child) {
  padding-bottom: 30px;
}

/* .play{
    width: 68px;
    height: 68px;
    border-radius: 50px;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
} */

/*about-section-end*/

/*client-section-start*/
.client-sec {
  background: #f7f9fe url("../img/client-bg.png") no-repeat center;
  width: 100%;
  background-size: cover;
}

.client-sec .client-title {
  padding: 0 0 60px 0;
}

.client-logo-box {
  background: var(--white);
  border-radius: 30px;
  padding: 50px 20px 50px 50px;
  box-shadow: 0px 0px 20px 0px #00000040;
  width: 100%;
  display: block;
  margin: 0 auto;
  position: relative;
}

.client-logo {
  width: 100%;
  border-radius: 20px;
  background: #f7f9fe;
  padding: 15px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.client-logo-item {
  padding: 0 30px 0 0;
}

.client-img img {
  width: 100%;
}

/*client-section-end*/

/*subject-section-start*/
.subject-sec {
  background: #f7f9fe url("../img/subject-bg.png") no-repeat bottom center;
  width: 100%;
  background-size: cover;
}

.project-box {
  position: relative;
  width: 100%;
}

.project-box:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 50%;
  background: linear-gradient(360deg, #188ac6 0%, rgba(26, 26, 25, 0) 100%);
  bottom: 0;
  border-radius: 20px;
}

.subject-sec .col-12:nth-child(2) .project-box:before {
  background: linear-gradient(360deg, #4ac966 0%, rgba(26, 26, 25, 0) 100%);
}

.project-info {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: 30px;
  width: 100%;
  padding: 0 20px;
}

.project-info > div {
  /* padding-right: 10px; */
}
.irrigation-box.project-box,
.project-box-content {
  height: 100%;
}
.project-img {
  width: 100%;
  height: 340px;
  object-fit: cover;
  border-radius: 20px;
  min-height: 100%;
}
.project-img img {
  width: 100%;
  border-radius: 20px;
  height: 100%;
  object-fit: cover;
}

.project-sub-title {
  font-size: var(--fs-26);
  color: var(--white);
  font-family: var(--font-bold);
}

.status {
  display: inline-block;
  font-size: var(--fs-18);
  font-family: var(--font-bold);
  color: var(--white);
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 8px 18px;
}

.sm-dec {
  padding: 40px 0;
}

.sm-points {
  display: inline-block;
  font-size: var(--fs-24);
  color: var(--gray-text);
  font-family: var(--font-semibold);
}

.subject-matter-points {
  padding: 0 0 40px 0;
}

.btn-container {
  display: flex;
  justify-content: start;
  align-items: center;
}

.cmn-btn {
  position: relative;
  right: 0;
  overflow: hidden;
  z-index: 1;
  transition: all 0.3s;
  font-size: var(--fs-24);
  color: var(--white);
  background: var(--green);
  padding: 15px 30px;
  width: 100%;
  max-width: 200px;
  border-radius: 10px;
  font-family: var(--font-bold);
  text-align: center;
}

.cmn-btn::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 150px;
  display: inline-block;
  border-radius: 0;
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 50%) rotate(12deg);
  transition: all 0.3s ease-out;
  opacity: 0;
  z-index: -1;
}

.cmn-btn::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 150px;
  display: inline-block;
  border-radius: 0;
  left: 50%;
  top: 50%;
  transform: translate(50%, 50%) rotate(12deg);
  transition: all 0.3s ease-out;
  opacity: 0;
  z-index: -1;
}

.primary-btn:hover {
  background-color: var(--white);
  color: var(--secondary);
}

.primary-btn:hover::before {
  background-color: var(--green);
}

.primary-btn:hover::after {
  background-color: var(--green);
}

.cmn-btn:hover::before {
  transform: translate(150%, 55%) rotate(20deg);
  opacity: 1;
  transition: all 0.3s ease-out;
}

.cmn-btn:hover::after {
  transform: translate(-150%, -55%) rotate(20deg);
  opacity: 1;
  transition: all 0.3s ease-out;
}

/*subject-section-end*/

/*Award-section-start*/
.award-sec {
  position: relative;
  width: 100%;
}

.award-left {
  margin: 0 -6px 0 0;
}

.award-box {
  border-radius: 20px;
  position: relative;
  margin: 20px;
  display: flex;
  flex-direction: column;
}

.award-box-img-div {
  height: 400px;
  overflow: hidden;
  border-radius: 20px;
  border: 1px solid #dddddd;
}
.award-box-img-div img {
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 100%;
}

.award-box .cmn-text {
  font-family: var(--font-regular);
}

.award-box img {
  border-radius: 20px;
}

.award-sec .award-box img {
  width: 100%;
}

.award-sub-title {
  color: var(--secondary);
  padding: 30px 0 20px 0;
}

.award-btns {
  display: flex;
  align-items: center;
  gap: 30px;
}

.award-prev-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 40px;
  border: 4px solid #4ac966;
  cursor: pointer;
}

.award-next-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 40px;
  border: 4px solid #4ac966;
  cursor: pointer;
}

.award-text {
  padding: 20px 0 40px 0;
}

/*Award-section-end*/

/*Conatct-section-start*/
.contactus-sec {
  background: url("../img/reach-us-bg.png") no-repeat center;
  width: 100%;
  background-size: cover;
}

.cta-box {
  background: linear-gradient(
    0deg,
    rgba(26, 26, 25, 0.8),
    rgba(26, 26, 25, 0.8)
  );
  border-radius: 30px;
  padding: 40px;
  border: 1px solid #eaeaea;
}

.form-control {
  border-radius: 10px;
  border: 2px solid #dbdbdb;
  padding: 18px 20px;
}

.form-control:hover {
  border: 2px solid #188ac6;
}

.ctc-form .form-control:focus {
  border: 2px solid #188ac6;
  box-shadow: none;
}

/* .contactus-sec .contact-title{
        color: var(--white);
    } */
.contactus-sec .cmn-text {
  font-family: var(--font-medium);
  font-size: var(--fs-20);
  padding: 10px 0 40px 0;
}

.cta-btn {
  color: var(--white);
  background-color: var(--secondary);
  border: 1px solid #0077b5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cta-btn:hover {
  background-color: var(--white);
  color: var(--secondary);
}

.cta-btn:hover::before {
  background-color: var(--secondary);
}

.cta-btn:hover::after {
  background-color: var(--secondary);
}

/*Conatct-section-end*/

/*footer-section-start*/
.site-footer {
  background: url("../img/Isolation_Mode.svg") no-repeat left center;
  width: 100%;
  position: relative;
  z-index: 1;
}

.site-address {
  cursor: pointer;
}

.site-footer:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  z-index: -1;
}

.footer-wrapper {
  padding: 88px 0 44px 0;
  border-bottom: 1px solid #fff;
}

.sm-list {
  display: flex;
  gap: 10px;
}

.sm-icon {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: var(--fs-26);
}

.linkedin {
  color: #0077b5;
}

.facebook {
  color: #316ff6;
}

.twitter {
  color: #14171a;
}

.sm-icon:hover {
  /* background: var(--secondary); */
  color: var(--white);
}

.footer-dec {
  padding: 30px 0 15px 0;
}

.cta-tel i {
  margin: 16px 0;
  display: inline-block;
}

.cmn-color {
  color: var(--secondary);
}

.footer-menu li {
  padding: 0 0 10px 0;
}

.privacy-policy {
  display: inline-block;
}

.terms-condition {
  display: inline-block;
}

.footer-btm {
  padding: 25px 0;
}

.copyright .cmn-text {
  font-size: var(--fs-16);
  font-family: var(--font-regular);
}

.footer-right img {
  padding: 0 8px 0 0;
}

.contact-link {
  padding-bottom: 10px;
}

/*footer-section-end*/

/*timeline-sec-start*/

.timeline-sec {
  position: relative;
  width: 100%;
}

.timeline__content {
  background-color: #f7f9fe;
  border-radius: 20px;
  border: none;
  box-shadow: 0px 0px 10px 0px #0000001a inset;
  padding: 30px;
  word-break: break-word;
}

.timeline-row {
  margin-right: 90px;
  margin-left: 90px;
}

.timeline__content .years {
  color: var(--secondary);
  font-size: var(--fs-26);
}

.timeline-btns {
  display: flex;
  align-items: center;
  gap: 30px;
  padding: 40px 0 0 0;
}

.timeline-prev-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 40px;
  border: 4px solid #4ac966;
  cursor: pointer;
}

.timeline-next-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 40px;
  border: 4px solid #4ac966;
  cursor: pointer;
}

.timeline__item {
  padding: 0 30px 0 0;
}

.timeline-btn-right {
  cursor: pointer;
  position: relative;
  top: 46px;
}

.timeline-btn-left {
  cursor: pointer;
  position: relative;
  top: 46px;
}

.timeline-circle.active {
  padding: 15px;
}

.vertical_alternating div ul {
  list-style: none;
  margin: 0;
  display: flex;
  align-items: center;
  background-color: #f5f5f5 !important;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  border-radius: 6px;
  flex-wrap: wrap;
  padding: 0.25rem;
  justify-content: center;
}

.vertical_alternating div ul li[aria-label="timeline_popover"]:nth-of-type(2) {
  display: none !important;
}

.rc-card {
  padding: 18px;
  min-height: auto !important;
}

.rc-card h2 {
  font-family: var(--font-semibold) !important;
  font-size: larger !important;
}

.rc-controls {
  background-color: transparent !important;
  box-shadow: none !important;
  height: 4rem;
  align-items: center;
}

.rc-controls li:nth-child(2) {
  height: 4rem !important;
}

.rc-controls li:nth-child(1) {
  height: 4rem !important;
}

.rc-controls li:nth-child(2) button {
  background-color: #ffffff !important;
  color: black !important;
}

.rc-controls li:nth-child(1) button {
  background-color: #ffffff !important;
  color: black !important;
}

.rc-controls li button[aria-controls="timeline-main-wrapper"] {
  border-radius: 12% !important;
  height: 3rem !important;
  width: 3rem !important;
}

.vertical-item-row div:nth-child(3)::after {
  width: 0.6rem !important;
}

.vertical-item-row div:nth-child(3)::before {
  width: 0.6rem !important;
}

/* .erdVAe::after {
  width: 0.6rem !important;
}
.erdVAe::before {
  width: 0.6rem !important;
} */

.timeline-vertical-circle {
  background-color: #ffffff !important;
  border-radius: 50%;
}

button[aria-label="select timeline"] div {
  padding: 0.8rem !important;
  margin-top: 0.6rem !important;
  margin-bottom: 0.6rem !important;
}

/*timeline-sec-end*/

.client-btns {
  display: flex;
  align-items: center;
  gap: 30px;
  /* padding: 40px 0 0 0; */
}
.client-prev-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 40px;
  border: none;
  cursor: pointer;
  position: absolute;
  left: -3%;
  top: 50%;
  bottom: 0;
  transform: translate(50%, -50%);
}
.client-next-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 40px;
  border: none;
  cursor: pointer;
  position: absolute;
  right: -3%;
  top: 50%;
  bottom: 0;
  transform: translate(-50%, -50%);
}

.client-img-wrapper {
  text-align: center;
  height: 100%;
}

.client-sub-title {
  color: var(--secondary);
  padding: 14px 0 0px 0;
}

.client-img-wrapper img {
  margin-inline: auto;
  max-height: 80px;
}

/*about-us-page-start*/
.since-sec {
  padding: 100px 0;
}

.about-banner {
  background-size: cover;
  width: 100%;
}

.about-banner .cmn-banner-text {
  letter-spacing: -1.5px;
}

.cmn-banner-page {
  width: 100%;
  position: relative;
  padding: 150px 0;
  margin: 99px 0 0 0;
  z-index: 1;
}

.cmn-banner-page::before {
  /* background: linear-gradient(10.12deg, rgba(20, 21, 22, 0.7) 7.78%, rgba(20, 21, 22, 0) 92.64%); */
  background: linear-gradient(
    10.12deg,
    rgba(20, 21, 22, 0.7) 7.78%,
    rgba(20, 21, 22, 0) 60.64%
  );
  position: absolute;
  content: "";
  bottom: 0;
  width: 100%;
  height: 45%;
  z-index: -1;
}

.projects-box {
  position: relative;
  border-radius: 20px;
}

.projects-box img {
  width: 100%;
  border-radius: 20px;
  height: 10rem;
}

.about-leftpart {
  position: relative;
}

.since-box {
  box-shadow: 0px 10px 30px 0px #0000001a;
  border-radius: 10px;
  background: var(--white);
  padding: 35px 15px 15px 30px;
  position: absolute;
  content: "";
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  width: 100%;
  max-width: 278px;
  margin-top: 0;
}

.since-number {
  font-size: var(--fs-90);
  font-family: var(--font-latoblack);
  -webkit-text-fill-color: white;
  -webkit-text-stroke: 2px #188ac6;
  line-height: 90px;
}

.since-text {
  font-size: var(--fs-40);
  font-family: var(--font-latoblack);
  letter-spacing: 21px;
  color: var(--sub-title-text);
  text-align: left;
  margin-left: 8px;
  line-height: 16px;
}

.about-rightpart .cmn-text {
  text-align: justify;
}

/*about-us-sec-end*/

/*glorious-sec-start*/
.glorious-sec {
  background: #f7f9fe url("../img/glorious-bg.png") no-repeat center;
  width: 100%;
  background-size: cover;
}

.glorious-content {
  padding: 100px 0;
}

/*glorious-sec-end*/

/*certificate-sec-start*/
.certificate-sec {
  position: relative;
  width: 100%;
  padding: 100px 0;
  background: url("../img/certificate-bg.png") no-repeat center;
  background-size: cover;
}

.certificate-box {
  background: var(--white);
  border-radius: 30px;
  padding: 16px;
  border: 1px solid #c0c0c0;
  min-height: 600px;
  word-wrap: break-word;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16);
  margin: 20px 20px 20px 0;
  overflow: hidden;
}

.certificate-title {
  padding: 25px 0 0 0;
  color: var(--secondary);
  font-family: var(--font-bold);
  text-align: center;
  font-size: var(--fs-24);
}

.certificate-img {
  width: 100%;
  border: 4px solid rgba(24, 138, 198, 1);
  border-radius: 22px;
}

/*certificate-sec-end*/

/*ourteam-sec-start*/
.our-team-sec {
  width: 100%;
  background-size: cover;
  position: relative;
}

.board-director {
  background-image: url("../img/certificate-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: fixed;
  width: 100%;
  background-size: cover;
  position: relative;
}

.key-management {
  background-color: #f7f9fe;
}
/* 
.our-team-left {
  padding: 0px 60px;
} */

.team-members-row {
  padding: 70px 0 0 0;
}

.team-members-row h6 {
  color: var(--secondary);
}

.team-members-row span {
  color: var(--gray-text);
}

.team-box {
  border-radius: 30px;
  background: var(--white);
  position: relative;
  transition: all 0.5s ease;
  z-index: 1;
  box-shadow: 0px 0px 30px 0px #0000001a inset;
  cursor: pointer;
}

/* .team-box:hover {
  background-color: var(--secondary);
  transition: all 0.5s ease;
} */

.team-hover-img-1 {
  position: absolute;
  content: "";
  width: 70px;
  height: 70px;
  left: 16px;
  top: 30px;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: -1;
}

.team-hover-img-2 {
  position: absolute;
  content: "";
  width: 70px;
  height: 70px;
  right: 5px;
  top: 30px;
  z-index: 1;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: -1;
}

.ceo-img-1 {
  position: absolute;
  content: "";
  width: 70px;
  height: 70px;
  left: 16px;
  top: 30px;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: -1;
}

.ceo-img-2 {
  position: absolute;
  content: "";
  width: 70px;
  height: 70px;
  right: 5px;
  top: 30px;
  z-index: 1;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: -1;
}

/* .team-box:hover .team-hover-img-1 {
  visibility: visible;
  transform: rotate(-90deg);
  transition: all 0.3s ease;
}

.team-box:hover .team-hover-img-2 {
  visibility: visible;
  transform: rotate(-90deg);
  transition: all 0.3s ease;
}

.team-box:hover .ceo-img-1 {
  visibility: visible;
  transform: rotate(75deg);
  transition: all 0.3s ease;
}

.team-box:hover .ceo-img-2 {
  visibility: visible;
  transform: rotate(75deg);
  transition: all 0.3s ease;
} */

.team-box .main-team-img {
  width: 100%;
  margin: -50px 0 0 0;
}

.team-info {
  padding: 20px 0 0 0;
}

.team-info span {
  color: var(--black);
  font-family: var(--font-bold);
  margin: 8px 0px;
  display: inline-block;
}

.team-popup-box {
  border-radius: 30px;
  width: 100%;
  overflow: hidden;
}

.team-popup-box > img {
  width: 100%;
}

.team-popup-box-content > h4 {
  font-size: var(--fs-26);
  font-family: var(--font-bold);
  color: var(--black);
  padding-bottom: 10px;
}

.team-popup-box-content > p {
  font-size: var(--fs-18);
  font-family: var(--font-regular);
  color: var(--black);
  text-align: justify;
}

.team-modal .btn-close {
  background: url("../img/xmark-black.svg") no-repeat !important;
  top: 10px;
  right: 10px;
}

.our-team-sec .modal-content {
  padding: 40px;
}

.our-team-sec .sm-list {
  justify-content: center;
  margin-top: 16px;
}

.our-team-sec .sm-list .linkedin {
  background-color: var(--white);
  color: var(--white);
  border: 1px solid black;
}

.our-team-sec .sm-list .facebook {
  background-color: var(--white);
  color: var(--white);
  border: 1px solid black;
}

.our-team-sec .sm-list .twitter {
  background-color: var(--white);
  color: var(--white);
  border: 1px solid black;
}

.bod-img {
  /* width: 100%; */
  cursor: pointer;
  width: 25%;
  height: 210px;
  padding: 8px;
  /* width: 140px;
  height: 165px; */
}

.bod-img img.active {
  filter: grayscale(0) brightness(0.9);
}
.bod-img img {
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: 100%;
  filter: grayscale(100%) brightness(0.7);
  transition: all ease-in-out 0.3s;
}
/* .bod-img img:hover{
    filter: grayscale(0) brightness(.9);
} */
.team-title {
  color: var(--secondary);
  font-size: var(--fs-22);
}
.team-left-content {
  display: flex;
  flex-wrap: wrap;
}

/*ourteam-sec-end*/

/*project-sec-start*/
.project-leftright-sec {
  padding: 100px 0;
}

.project-leftright-sec-bg {
  padding: 100px 0;
}

.project-banner {
  background: url("../img/project-bg.png") no-repeat center;
  background-size: cover;
  width: 100%;
}

.project-banner .cmn-banner-text {
  letter-spacing: 2.5px;
}

.proleri-left .cmn-text {
  font-size: var(--fs-18);
  padding: 20px 0 40px 0;
  font-family: var(--font-regular);
}

.project-title {
  font-size: var(--fs-26);
  color: var(--secondary);
  font-family: var(--font-bold);
}

.project-dec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f7f9fe;
  padding: 25px;
  border-radius: 10px;
  min-height: 120px;
}

.project-dec .cmn-text {
  font-family: var(--font-latoblack);
  color: var(--black);
  font-size: var(--fs-32);
}

.plabel {
  font-size: var(--fs-24);
  color: var(--sub-title-text);
  font-weight: 900;
  letter-spacing: 2px;
}

.projectslider-box {
  width: 100%;
  overflow: hidden;
  border-radius: 20px;
  height: 400px;
}

.projectslider-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.proleri-rightpart {
  position: relative;
}

.project-bio {
  box-shadow: 0px 10px 30px 0px #0000001a;
  border-radius: 40px;
  background: var(--white);
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  content: "";
  top: -50px;
  left: -30px;
}

.p-year .year-text {
  text-align: right;
}

.pname {
  font-size: var(--fs-40);
  font-family: var(--font-latoblack);
  color: var(--sub-title-text);
  letter-spacing: 8px;
  padding: 0 0 0 34px;
}

.value-text {
  text-align: end;
}

.proleri-rightpart .slick-dots {
  position: absolute;
  display: flex !important;
  justify-content: center;
  width: 100%;
  margin: 20px 0 0 0;
}

.proleri-rightpart .slick-dots button {
  width: 10px;
  height: 10px;
  border-radius: 20px;
  display: block;
  padding: 0;
  border: none;
  background-color: var(--sub-title-text);
  text-indent: -9999px;
}

.proleri-rightpart li.slick-active button {
  background-color: var(--secondary);
}

.proleri-rightpart li {
  margin: 0 0 0 10px;
}

.project-leftright-sec-bg {
  width: 100%;
  background-color: var(--off-white);
}

.project-leftright-sec-bg .project-dec {
  background: var(--white);
}

/*project-sec-end*/

table {
  text-align: center;
  border-color: var(--primary);
}

table thead {
  background: var(--primary) !important;
  /* color: var(--white) !important; */
}

table .table-bordered {
  border-color: var(--primary);
}

.turnover-data-row {
  padding: 50px 0 0 0;
}

.table > :not(caption) > * > * {
  padding: 27px 0;
}

table th {
  font-family: var(--font-regular);
  font-size: var(--fs-22);
  color: var(--white) !important;
  background-color: var(--primary) !important;
}

table td {
  font-family: var(--font-bold);
  font-size: var(--fs-22);
  color: var(--black);
}

table img {
  width: 30px;
}

/*gallery-page -start*/
.gallery-banner {
  width: 100%;
  background: url("../img/gallery-bg.png") no-repeat center;
  background-size: cover;
}

.gallery-box {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
}

.gallery-box::before {
  position: absolute;
  content: "";
  background: linear-gradient(360deg, #2a2d2f 0%, rgba(42, 45, 47, 0) 100%);
  width: 100%;
  height: 50%;
  bottom: 0;
}

.gallery-content {
  position: absolute;
  bottom: 30px;
  width: 100%;
  padding: 0 30px;
}

.gallery-box .cmn-text {
  font-size: 24px;
  color: var(--white);
  font-family: var(--font-bold);
}

.gallery-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-box a {
  height: 100%;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 200px;
}

/* .grid-wrapper > div {
  display: flex;
  justify-content: center;
  align-items: center;
} */

.grid-wrapper > div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0 0 20px 20px;
}

.grid-wrapper {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-auto-rows: 200px;
  grid-auto-flow: dense;
}

.gallery-banner .cmn-banner-text {
  letter-spacing: 3.5px;
}

.grid-wrapper .wide {
  grid-column: span 2;
}

.grid-wrapper .tall {
  grid-row: span 2;
}

.grid-wrapper .big {
  grid-column: span 2;
  grid-row: span 2;
}

.gallery-sec .modal .btn-close {
  background-color: var(--white);
}

.btn-close {
  background-color: var(--white);
}

/* .modal-btns{
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
} */

.modal-content .carousel-item img {
  height: 360px;
  margin: auto;
  display: block;
}

.btn-close {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  z-index: 999;
}

.carousel-control-prev {
  background: linear-gradient(90deg, #25282b 0%, rgba(37, 40, 43, 0.2) 50%);
  opacity: unset;
}

.carousel-control-next {
  background: linear-gradient(-90deg, #25282b 0%, rgba(37, 40, 43, 0.2) 50%);
  opacity: unset;
}

.carousel-control-prev-icon {
  background-size: 20px;
}

.carousel-control-next-icon {
  background-size: 20px;
}

.gallery-menu .nav-underline .nav-link {
  font-size: var(--fs-20);
  font-family: var(--font-regular);
  color: var(--primary);
}

.nav-underline .nav-link:hover {
  border-bottom-color: var(--secondary);
}

.gallery-menu .nav-underline .nav-link.active {
  color: var(--secondary);
  font-family: var(--font-regular);
  font-size: var(--fs-20);
  font-weight: initial;
}

.gallery-menu .nav-underline {
  margin-top: 30px;
}

.rkc-videos .video-box {
  width: 100%;
  height: 200px;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
}

.rkc-videos .video-box:before {
  position: absolute;
  content: "";
  background: linear-gradient(360deg, #2a2d2f 0%, rgba(42, 45, 47, 0) 100%);
  width: 100%;
  height: 50%;
  bottom: 0;
  border-radius: 0 0 20px 20px;
}

.rkc-videos .play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  cursor: pointer;
}

.rkc-modalvideobox {
  height: 300px;
  width: 100%;
}

/*gallery-page -end*/

/*career-page-start*/
.careers-banner {
  background: url("../img/careear-opening.png") no-repeat center;
  background-size: cover;
  width: 100%;
}

.careers-banner .cmn-banner-text {
  letter-spacing: 17px;
}

.opening-box {
  padding: 40px;
  border-radius: 30px;
  background: var(--white);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  height: 100%;
}

.opening-title-with-logo{
  display: flex;
  align-items: center;
  justify-content: center;
}

.opening-box .post-dec{
  flex: 1;
}
.opening-title-with-logo .post-name{
  flex: 1;
}
/* .opening-box > .helmet-img {
  display: inline-block;
} */

.opening-box .opening-title-with-logo .post-name {
  display: inline-block;
  padding: 0 0 0 20px;
  font-family: var(--font-bold);
  color: var(--secondary);
  flex: 1;
}

.opening-box > p {
  padding: 45px 0 70px 0;
  font-family: var(--font-regular);
  font-size: var(--fs-18);
  color: var(--off-gray);
}

.odd-row-left,
.even-row-right {
  border-radius: 12px;
  overflow: hidden;
}

.odd-row-left > img {
  width: 100%;
}

.even-row-right > img {
  width: 100%;
}

.cr-leftright-sec p {
  font-size: var(--fs-20);
  color: var(--black);
  font-family: var(--font-medium);
  text-align: justify;
}

/*career-page-end*/

/*career-opening-page-start*/
.cropen-banner {
  background: url("../img/careear-opening.png") no-repeat center;
  background-size: cover;
  width: 100%;
}

.candidate-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.candidate-details .cmn-text {
  font-size: var(--fs-26);
  color: var(--black);
  font-family: var(--font-semibold);
}

.candidate-details span {
  font-size: var(--fs-26);
  color: var(--secondary);
  font-family: var(--font-semibold);
}

.progress-container {
  /* margin-bottom: 30px; */
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.progress-container::before {
  background-color: #d9d9d9;
  content: "";
  height: 4px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 2;
}

.progress-container::after {
  background-color: #3498db;
  content: "";
  height: 4px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 2;
}

.progress {
  background-color: #3498db;
  height: 4px;
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.4s ease;
  width: 0%;
  z-index: 80;
}

/* .step{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
  } */
.cmn-tab {
  background-color: var(--white);
  border-radius: 16px;
  padding: 30px;
  display: flex;
  position: relative;
  border: 2px solid #d9d9d9;
  width: 383px;
  color: var(--off-gray);
  transition: 0.4 ease;
  position: relative;
  z-index: 50;
}

.cmn-tab.active::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: var(--white);
  right: 0;
  bottom: -16px;
  right: 50%;
  border-bottom: 2px solid #0077b5;
  border-right: 2px solid #0077b5;
  transform: translate(50%, 0) rotate(45deg);
  z-index: 1;
}

.form-wizard .progress-container:nth-child(1) .cmn-tab::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: var(--white);
  right: 0;
  bottom: -9px;
  right: 50%;
  border-bottom: 2px solid #0077b5;
  border-right: 2px solid #0077b5;
  transform: translate(50%, 0) rotate(45deg);
  z-index: 1;
}

.indicator {
  font-size: 14px;
  border-color: var(--secondary);
  color: var(--secondary);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  width: 36px;
  height: 36px;
  position: relative;
  z-index: 1;
  border-radius: 50%;
  border: 2px solid #d9d9d9;
}

.indicator > .fa-check {
  display: none;
}

.tab-1 .fa-circle {
  display: block;
}

.tab-2.tab-3,
.fa-circle {
  display: none;
}

.cmn-tab.finish {
  background-color: var(--secondary);
  color: var(--white);
}

.indicator.finish {
  background-color: var(--white);
  color: var(--secondary);
}

.cmn-tab.active {
  border: 2px solid #0077b5;
}

.cmn-tab.prevtab {
  background-color: #0077b5;
}

.cmn-tab.prevtab span {
  color: #ffffff;
}

.cmn-tab span {
  font-size: var(--fs-24);
  font-family: var(--font-medium);
  color: var(--secondary);
  margin: 0 0 0 10px;
}

.tab-content {
  background: var(--white);
  border-radius: 30px;
  display: block;
  box-shadow: 0px 0px 20px 0px #00000040;
  padding: 40px;
}

.file-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border: 1px dashed rgba(107, 104, 104, 0.836);
  background-color: #fff;
}

.file-upload > p {
  font-family: var(--font-regular);
  font-size: var(--fs-20);
  color: var(--sub-title-text);
  cursor: pointer;
}

.form-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 40px auto auto;
}

.form-btn-container .btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: var(--secondary);
  border-radius: 10px;
  padding: 16px 30px;
  max-width: 200px;
  color: var(--white);
  border: none;
}

.form-btn-container .prev-btn {
  background-color: var(--black);
}

.form-btn-container .btn:active {
  transform: scale(0.98);
}

.form-btn-container > button {
  font-family: var(--font-medium);
  font-size: var(--fs-20);
  color: var(--white);
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  opacity: 1;
}

.rkc-form h4 {
  /* padding-bottom: 30px; */
  font-family: var(--font-semibold);
  font-size: var(--fs-26);
  color: var(--black);
}

/*career-opening-page-end*/

/*b2b--page-start*/
.b2b-banner {
  background: url("../img/b2b-bg.png") no-repeat center;
  width: 100%;
  background-size: cover;
}

.b2b-banner .cmn-banner-title {
  letter-spacing: 7px;
}

.b2b-banner .cmn-banner-text {
  letter-spacing: 10.5px;
}

.vendorbox {
  background: var(--white);
  border-radius: 30px;
  border: 1px solid #f7f9fe;
  padding: 30px;
  box-shadow: 0px 0px 30px 0px #00000029;
  width: 100%;
  display: inline-block;
  min-height: 200px;
}

.vendor-data {
  flex: 1;
}

.vendor-wrap {
  display: flex;
  align-items: center;
  gap: 20px;
}

.vendor-icon {
  width: 100px;
  height: 100px;
  display: block;
  background: var(--white);
  overflow: hidden;
  border: 1px solid #eaeaea;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vendorbox .project-sub-title {
  color: var(--secondary);
}

.add {
  display: block;
  padding: 8px;
  border: 1px solid #eaeaea;
  border-radius: 10px;
}

.delete {
  display: block;
  padding: 8px;
  border: 1px solid #eaeaea;
  border-radius: 10px;
}

.rkc-form .form-control {
  min-height: 60px;
}

.rkc-form ::placeholder {
  color: #d9d9d9;
}

.gst-detail {
  background-color: #f7f9fe;
  width: 100%;
  padding: 30px;
}

.supply-add {
  background-color: #f7f9fe;
  width: 100%;
  padding: 30px;
}

.operations {
  display: flex;
  gap: 30px;
}

/*b2b--page-end*/

/*contact--page--start*/
.contact-banner {
  background: url("../img/contact-banner.png") no-repeat center;
  background-size: cover;
  width: 100%;
}

.rkc-map {
  width: 100%;
  display: block;
  overflow: hidden;
}

.contact-form {
  width: 100%;
  background-color: var(--off-white);
  display: block;
}

.contact-form-head > h3 {
  font-size: var(--fs-26);
  font-family: var(--font-bold);
  color: var(--black);
}

.contact-form-head > h4 {
  font-size: var(--fs-26);
  font-family: var(--font-bold);
  color: var(--black);
}

.contact-form-head > p {
  font-size: var(--fs-18);
  font-family: var(--font-regular);
  color: var(--black);
}

.contact-details span {
  font-size: var(--fs-18);
  font-family: var(--font-regular);
  color: var(--black);
}

.contact-details address {
  font-size: var(--fs-18);
  font-family: var(--font-regular);
  color: var(--black);
  text-align: justify;
}

.contact-righttitle {
  position: relative;
}

.contact-righttitle:before {
  position: absolute;
  content: "";
  border-left: 2px solid #0077b5;
  height: 30px;
}

.contact-form h4 {
  padding-left: 30px;
  color: var(--secondary);
  font-size: var(--fs-26);
  font-family: var(--font-bold);
}

.contact-details img {
  width: 30px;
  height: 30px;
}

.ctc-brochure {
  background-color: var(--white);
  border-radius: 16px;
  padding: 30px;
}

.ctc-brochure .link-ctc {
  display: inline-block;
  color: var(--gray-text);
  font-size: var(--fs-18);
  font-family: var(--font-regular);
}

/*contact--page--end*/

/*rkgroup--page--end*/

/*news--page--start*/
.news-banner {
  background: url("../img/news.png") no-repeat center;
  background-size: cover;
  width: 100%;
}

.news-card-sec {
  background-color: var(--off-white);
}

.news-box {
  background-color: var(--white);
  border-radius: 30px;
  overflow: hidden;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.news-content {
  padding: 30px;
}

.news-img {
  height: 300px;
  width: 100%;
}

.news-popup {
  padding: 0px 16px 0px 16px;
}

.news-box img {
  width: 100%;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  height: 100%;
  object-fit: cover;
}

.news-banner-title {
  letter-spacing: 5px;
}

.modal .modal-dialog .news-box {
  overflow: initial;
}

.modal-header {
  padding: 0;
}

.modal-img {
  height: 300px;
  width: 100%;
}

.modal-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.news-dec {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 2;
  -webkit-line-clamp: 2;
}

/*news--page--end*/

.vendorcode-search {
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
}

.exvndorcode {
  width: 100%;
  max-width: 486px;
}

.vsearch-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 200px;
  width: 100%;
  background-color: var(--secondary);
  padding: 10px 22px;
  border-radius: 10px;
}

.vsearch {
  font-family: var(--font-medium);
  font-size: var(--fs-24);
  color: var(--white);
}

.policy-box {
  background-color: var(--white);
  border-radius: 30px;
  overflow: hidden;
  cursor: pointer;
  display: inline-block;
  height: 100%;
  width: 100%;
}

.policy-content {
  padding: 30px;
}

.policy-box img {
  width: 100%;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  height: 300px;
}

.policy-banner a {
  text-decoration: underline;
}

.policies-content h3 {
  color: var(--secondary);
  font-size: var(--fs-24);
  font-family: var(--font-semibold);
  padding: 20px 0;
}

.policies-content h4 {
  color: var(--off-gray);
  font-size: var(--fs-24);
  font-family: var(--font-semibold);
  padding-bottom: 20px;
}

.policies-content h6 {
  color: var(--black);
  font-size: var(--fs-20);
  font-family: var(--font-medium);
  padding-bottom: 20px;
}

.policies-content p {
  padding-bottom: 16px;
  text-align: justify;
  font-family: var(--font-regular);
  color: var(--black);
}

.policies-content span {
  color: var(--black);
  font-size: var(--fs-18);
  font-family: var(--font-semibold);
  padding-bottom: 20px;
}

.policies-content ul li {
  list-style-type: lower-roman;
  list-style-position: inside;
  padding: 0 0 10px 20px;
  font-family: var(--font-regular);
  text-align: justify;
  color: var(--black);
}

.policies-content ol li {
  list-style-type: decimal;
  list-style-position: inside;
  padding: 0 0 10px 20px;
  font-family: var(--font-regular);
  text-align: justify;
  color: var(--black);
}

#notfound {
  position: relative;
  height: 100vh;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 560px;
  width: 100%;
  padding-left: 160px;
  line-height: 1.1;
}

.notfound .notfound-404 {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  width: 140px;
  height: 140px;
  background-image: url("../img/emoji.png");
  background-size: cover;
}

.notfound .notfound-404:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(2.4);
  -ms-transform: scale(2.4);
  transform: scale(2.4);
  border-radius: 50%;
  background-color: #f2f5f8;
  z-index: -1;
}

.notfound h1 {
  font-family: "Nunito", sans-serif;
  font-size: 65px;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 10px;
  color: #151723;
  text-transform: uppercase;
}

.notfound h2 {
  font-family: "Nunito", sans-serif;
  font-size: 21px;
  font-weight: 400;
  margin: 0;
  text-transform: uppercase;
  color: #151723;
}

.notfound p {
  font-family: "Nunito", sans-serif;
  color: #999fa5;
  font-weight: 400;
}

.notfound a {
  font-family: "Nunito", sans-serif;
  display: inline-block;
  font-weight: 700;
  border-radius: 40px;
  text-decoration: none;
  color: #388dbc;
}

@media only screen and (max-width: 767px) {
  .notfound .notfound-404 {
    width: 110px;
    height: 110px;
  }

  .notfound {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 110px;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: none;
  appearance: none;
}

input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

/* OTP Verification popup start */
.form-control:focus {
  box-shadow: none;
  border: 2px solid var(--secondary);
}

.validate {
  border-radius: 20px;
  height: 40px;
  width: 140px;
}

/* OTP Verification popup end */

/* Imported Links Section Start */

.implink-box {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 20px;
}

.implink-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Imported Links Section End */

/* Inspiration section start */

.inspiration-section {
  width: 100%;
  display: block;
}

.our-inspiration {
  width: 100%;
  height: 500px;
}

.our-inspiration img {
  width: 100%;
  height: 100%;
}

/* .inspiration-section .container {
  padding: initial;
} */

/* .inspiration-section .row .col-12:nth-child(2) {
  padding: initial;
} */

.custom-date-input {
  position: relative;
  display: inline-block;
}

.custom-date-input input {
  padding: 8px;
  font-size: 16px;
}

.custom-date-input .placeholder {
  position: absolute;
  top: 8px;
  left: 8px;
  color: #999;
  pointer-events: none;
  font-size: 16px;
}

.custom-date-input input.has-value + .placeholder {
  display: none;
}
