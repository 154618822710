@media (max-width: 1680px) {
  .custom-container {
    max-width: 1520px;
  }
  .cmn-banner-page {
    padding: 124px 0;
  }
  .custom-collapse .custom-item {
    padding: 0 50px 0 0;
  }
  .custom-collapse .contact-top-btn {
    padding: 18px 24px;
    font-size: var(--fs-22);
  }
  .since-sec {
    padding: 80px 0;
  }
  .cmn-sec-padding {
    padding: 85px 0;
  }
  .vision-right {
    padding: 78px 0 170px 0;
  }
  .mission-left {
    padding: 85px 0;
  }
  .glorious-content {
    padding: 85px 0;
  }
  .banner-image {
    min-height: 680px;
  }
  .since-box {
    max-width: 228px;
  }
  .since-number {
    font-size: var(--fs-70);
    line-height: 70px;
  }
  .since-text {
    letter-spacing: 10px;
  }

  .banner-bottom-box {
    max-width: 478px;
  }
  .banner-btm-text {
    font-size: 24px;
  }

  .top-banner-section {
    padding: 75px 0 0 0;
  }
  .cmn-sub-title {
    font-size: var(--fs-56);
  }
  .cmn-banner-title {
    font-size: var(--fs-56);
    line-height: 46px;
  }
  .cmn-banner-text {
    font-size: var(--fs-40);
  }
  .cmn-btn {
    max-width: 166px;
    font-size: var(--fs-20);
    padding: 14px 24px;
  }
  .project-banner .cmn-banner-text {
    letter-spacing: 2px;
  }
  .gallery-banner .cmn-banner-text {
    letter-spacing: 2.8px;
  }
  .careers-banner .cmn-banner-text {
    letter-spacing: 13.5px;
  }
  .b2b-banner .cmn-banner-text {
    letter-spacing: 9.5px;
  }
  .news-banner-title {
    letter-spacing: 4px;
  }
  .bod-img {
    width: 33%;
    height: 320px;
  }
}

@media (max-width: 1500px) {
  .custom-container {
    max-width: 1420px;
  }
  .cmn-sub-title {
    font-size: var(--fs-50);
  }
  .since-sec {
    padding: 70px 0;
  }
  .cmn-sec-padding {
    padding: 70px 0;
  }
  .mission-left {
    padding: 70px 0;
  }
  .glorious-content {
    padding: 70px 0;
  }
  .certificate-sec {
    padding: 80px 0;
  }
  .project-bio img {
    width: 70px;
  }
  .banner-image {
    min-height: 580px;
  }
  .contactus-sec .cmn-text {
    padding: 10px 0 30px 0;
  }
  .bod-img {
    width: 33%;
    height: 320px;
  }
}

@media (max-width: 1399px) {
  .projectslider-box {
    height: 400px;
  }
  .custom-container {
    max-width: 1320px;
  }
  .cmn-banner-page {
    margin: 90px 0 0 0;
    padding: 100px 0;
  }
  .cmn-banner-title {
    font-size: var(--fs-46);
    line-height: 24px;
  }
  .cmn-banner-text {
    font-size: var(--fs-34);
  }
  .custom-collapse .custom-item {
    padding: 0 40px 0 0;
  }
  .banner-image {
    min-height: 480px;
  }
  .since-sec {
    padding: 100px 0;
  }
  .cmn-sec-padding {
    padding: 60px 0;
  }
  .mission-left {
    padding: 60px 0;
  }
  .vision-right {
    padding: 66px 0 144px 0;
  }
  .glorious-content {
    padding: 60px 0;
  }

  .certificate-sec {
    padding: 100px 0;
  }
  .banner-btm-text {
    font-size: var(--fs-22);
  }

  .custom-collapse .ctc-top-text {
    font-size: var(--fs-20) !important;
  }
  .custom-navbar .custom-link {
    font-size: var(--fs-18);
  }
  .sm-points {
    font-size: var(--fs-16);
  }
  .cmn-sub-title {
    font-size: var(--fs-40);
  }
  .cmn-text {
    font-size: var(--fs-18);
  }
  .contactus-sec .cmn-text {
    font-size: var(--fs-18);
  }

  .top-banner-section .slick-dots button {
    width: 16px;
    height: 16px;
  }
  .since-number {
    font-size: var(--fs-60);
  }
  .since-text {
    letter-spacing: 4px;
  }
  .project-sub-title {
    font-size: var(--fs-22);
    /* padding-left: 12px; */
  }
  .project-dec img {
    width: 40px;
  }
  .project-dec .cmn-text {
    font-size: var(--fs-24);
  }
  .project-bio img {
    width: 50px;
  }
  .since-box {
    max-width: 210px;
  }
  .since-number {
    line-height: 70px;
  }
  .certificate-box {
    min-height: 521px;
  }
  .certificate-title {
    font-size: var(--fs-20);
  }
  .form-control {
    padding: 14px 20px;
    font-size: var(--fs-14);
  }
  .contactus-sec .cmn-text {
    padding: 10px 0 24px 0;
  }

  .table > :not(caption) > * > * {
    padding: 16px 0;
  }
  .cmn-btn {
    max-width: 148px;
    font-size: var(--fs-20);
    padding: 10px 22px;
  }
  .footer-cmn-title {
    font-size: var(--fs-22);
  }
  .gallery-box .cmn-text {
    font-size: var(--fs-20);
  }
  .cmn-tab {
    width: 272px;
  }

  .project-banner .cmn-banner-text {
    letter-spacing: 1px;
  }
  .gallery-banner .cmn-banner-text {
    letter-spacing: 1.8px;
  }
  .careers-banner .cmn-banner-text {
    letter-spacing: 9.5px;
  }
  .b2b-banner .cmn-banner-text {
    letter-spacing: 8.2px;
  }
  .candidate-details .cmn-text {
    font-size: var(--fs-18);
  }
  .candidate-details span {
    font-size: var(--fs-18);
  }
  .project-title {
    font-size: var(--fs-22);
  }
  .video-title {
    font-size: var(--fs-20);
  }
  .bod-img {
    width: 25%;
    height: 200px;
  }
}

@media (max-width: 1280px) {
  .custom-container {
    max-width: 1220px;
  }

  .about-sec .about-right {
    padding: 70px 0 0 0;
  }
  .projectslider-box {
    height: 300px;
  }
}

@media (max-width: 1199px) {
  .project-info > div {
    /* padding-right: 10px; */
  }
  .custom-container {
    max-width: 960px;
  }
  .custom-collapse .custom-item {
    padding: 0 18px 0 0;
  }
  .cmn-banner-page {
    margin: 85px 0 0 0;
    padding: 80px 0;
  }
  .banner-image {
    min-height: 420px;
  }
  .banner-btm-text {
    font-size: var(--fs-20);
  }

  .cmp-intro .cmp-videobg-img {
    width: 100%;
  }
  .about-sec .about-right {
    padding: 70px 0 0 0;
  }
  .about-right-image img {
    width: 460px;
  }
  .project-sub-title {
    font-size: var(--fs-20);
  }
  .video-content {
    transform: translate(-50%, 40%);
  }
  .video-content img {
    width: 44px;
  }
  .brochure-content img {
    width: 35px;
  }
  .cmp-intro .brochure-text {
    padding: 10px 0 0 0;
  }

  .status {
    font-size: var(--fs-16);
    padding: 8px 10px;
  }
  .cmn-sub-title {
    font-size: var(--fs-34);
  }
  .cmp-intro .video-text {
    padding: 15px 0 0 0;
  }
  .award-sec {
    padding: 100px 0 100px 75px;
  }
  .project-dec {
    max-height: 90px;
  }
  .project-dec .cmn-text {
    font-size: var(--fs-20);
  }
  .plabel {
    font-size: var(--fs-20);
  }
  .pname {
    font-size: var(--fs-32);
  }
  .footer-cmn-title {
    font-size: var(--fs-22);
  }
  .link-text {
    font-size: var(--fs-16);
  }

  .sm-icon {
    width: 38px;
    height: 38px;
  }
  table th {
    font-size: var(--fs-18);
  }
  .opening-box {
    padding: 20px;
  }
  .helmet-img > img {
    width: 45px;
  }
  .opening-box .btn-container {
    justify-content: start;
  }
  .cmn-tab {
    width: 200px;
    padding: 20px;
  }
  .cmn-tab span {
    font-size: var(--fs-18);
  }
  .cmn-tab .indicator {
    width: 24px;
    height: 24px;
  }
  .rkc-form label {
    font-size: var(--fs-18);
  }
  .add img {
    width: 30px;
  }
  .delete img {
    width: 30px;
  }
  .form-btn-container .btn {
    max-width: 150px;
    padding: 10px 20px;
  }
  .project-title {
    font-size: var(--fs-22);
  }
  .policy-box img {
    height: 240px;
  }
  .video-title {
    font-size: var(--fs-20);
  }
  .bod-img {
    width: 33%;
    height: 240px;
  }
  .form-control {
    padding: 10px 20px;
  }
}

@media (max-width: 991px) {
  .project-info > div {
    /* padding-right: 30px; */
  }
  .client-next-btn,
  .client-prev-btn {
    width: 50px;
  }
  .btn-container {
    justify-content: center;
  }
  .cmn-banner-title {
    font-size: var(--fs-34);
  }
  .cmn-banner-text {
    font-size: var(--fs-26);
  }
  .banner-image {
    min-height: 360px;
  }
  .cmn-banner-page {
    margin: 60px 0 0 0;
  }
  .custom-collapse .custom-item {
    padding: 0 16px 0 0;
  }
  .cmn-sub-title {
    font-size: var(--fs-28);
  }
  .banner-btm-text {
    font-size: var(--fs-18);
  }

  .top-banner-section {
    padding: 76px 0 0 0;
  }
  .about-sec .about-right {
    padding: unset;
    text-align: left;
  }

  .custom-container {
    max-width: 720px;
  }
  .about-right img {
    padding: unset;
    width: 100%;
  }
  .about-left {
    padding: 30px 0 0 0;
  }
  .since-sec {
    padding: 50px 0;
  }
  .cmn-sec-padding {
    padding: 30px 0 70px 0;
  }
  .vision-right {
    padding: 48px 0 104px 0;
  }
  .mision-left {
    padding: 30px 0 70px 0;
  }
  .glorious-content {
    padding: 30px 0 70px 0;
  }
  .certificate-sec {
    padding: 40px 0 80px 0;
    margin: 0 0 20px 0;
  }
  .subject-right-content {
    padding: 30px 0 0 0;
  }
  .site-header {
    padding: 15px 0;
  }
  .award-left {
    text-align: center;
  }
  .award-item {
    padding: 30px 0 0 0;
  }
  .award-box {
    text-align: center;
  }
  .award-btns {
    justify-content: center;
  }
  .timeline-btns {
    justify-content: center;
  }
  .timeline__item {
    padding: unset;
  }
  .award-sec .award-btns {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .certificate-sec .certificate-btns {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .timeline-prev-btn {
    width: 40px;
    height: 40px;
  }
  .timeline-next-btn {
    width: 40px;
    height: 40px;
  }
  .project-leftright-sec .row {
    flex-direction: column-reverse;
  }

  .team-info {
    padding: 14px 0 0 0;
  }
  .team-title {
    font-size: var(--fs-18);
  }
  .team-info .cmn-text {
    font-size: var(--fs-16);
  }

  .cr-leftright-sec p {
    font-size: var(--fs-16);
  }
  .project-banner .cmn-banner-text {
    letter-spacing: 0.4px;
  }
  .gallery-banner .cmn-banner-text {
    letter-spacing: 1px;
  }
  .careers-banner .cmn-banner-text {
    letter-spacing: 7.5px;
  }
  .b2b-banner .cmn-banner-text {
    letter-spacing: 6.7px;
  }
  .project-title {
    font-size: var(--fs-18);
  }
  .project-sub-title {
    font-size: var(--fs-18);
    /* padding-left: 20px; */
  }
  .core-value-sec::before {
    width: 100%;
  }
  .policy-box img {
    height: 220px;
  }
  .award-text {
    padding: 20px 0 0 0;
  }
  .custom-collapse .contact-top-btn {
    padding: 10px 16px;
  }
  .video-title {
    font-size: var(--fs-20);
  }
  .client-btns {
    justify-content: center;
  }
  .bod-img {
    width: 25%;
    height: 220px;
  }
}

@media (max-width: 767px) {
  .test-img {
    position: relative;
  }
  .test-img::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    );
    z-index: 0;
  }
  .our-inspiration {
    height: auto;
  }
  .timeline-btn-left-mob,
  .timeline-btn-right-mob {
    position: unset;
    margin-top: 35px;
  }
  .client-next-btn,
  .client-prev-btn {
    width: 40px;
  }

  .imp-links > div > div > a {
    height: fit-content;
  }

  .since-sec {
    padding: 38px 0;
  }
  .vision-right {
    padding: 36px 0 50px 0;
  }
  .mision-left {
    padding: 30px 0 50px 0;
  }
  .glorious-content {
    padding: 30px 0 50px 0;
  }

  .banner-bottom-box {
    padding: 24px;
  }

  .brochure-content {
    transform: none;
    padding: 20px;
  }
  .cmp-intro .cmp-videobg-img {
    width: 100%;
  }
  .cmp-video-img {
    display: flex;
    justify-content: center;
  }
  .video-content {
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
  }

  .readmore-btn {
    margin: 0 auto;
  }
  .footer-wrapper {
    padding: 40px 0 24px 0;
  }
  .getin-touch-row {
    padding: 20px 0 0 0;
  }
  .site-footer {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .sm-list {
    justify-content: center;
  }
  .footer-btm .d-flex {
    flex-wrap: wrap;
  }

  .navbar-brand {
    padding: unset;
  }
  .cmp-intro {
    margin: 0 0 30px 0;
  }
  .cr-leftright-sec .even-row {
    flex-direction: column-reverse;
  }
  .cmn-banner-title {
    line-height: 20px;
  }
  .progress-container {
    flex-direction: column;
    gap: 24px;
  }
  .cmn-tab {
    width: 100%;
  }
  .progress {
    display: none;
  }
  .supply-add {
    padding: 16px;
  }
  .gst-detail {
    padding: 16px;
  }
  .tab-content {
    padding: 20px;
  }
  .form-btn-container {
    flex-direction: column;
  }
  .candidate-details {
    flex-direction: column;
    text-align: center;
  }
  .candidate-details .cmn-text {
    font-size: var(--fs-16);
  }
  .candidate-details .span {
    font-size: var(--fs-16);
  }
  .video-title {
    font-size: var(--fs-16);
  }
  /* .team-left-content {
    flex-wrap: nowrap;
  } */
  .bod-img {
    width: 25%;
    height: 210px;
  }
  .footer-right > .row .col-xl-6:not(:last-child),
  .footer-right > .row .col-xl-3:not(:last-child) {
    margin-bottom: 30px;
  }
  .footer-right {
    text-align: left;
    padding-left: 30px;
    padding-right: 30px;
  }
  .award-wrap .slick-slider .slick-arrow {
    display: none !important;
  }
  .certificate-wrap .slick-slider .slick-arrow {
    display: none !important;
  }
  .horizontal.timeline-main-wrapper {
    display: none !important;
  }
}
@media (max-width: 576px) {
  .client-next-btn,
  .client-prev-btn {
    width: 30px;
  }

  .imp-links > div > div > a {
    height: fit-content;
  }

  .inputs input {
    width: 2.5rem;
    padding: 0.3rem;
    font-size: 0.9rem;
  }
  .projectslider-box {
    height: 300px;
  }
  .cmn-banner-title {
    font-size: var(--fs-22);
    line-height: 16px;
  }
  .cmn-text {
    font-size: var(--fs-16);
  }
  .cmn-banner-text {
    font-size: var(--fs-16);
  }
  .cmn-sec-padding {
    padding: 30px 0 50px 0;
  }

  .banner-bottom-box {
    width: 100%;
    left: 0;
  }
  .top-banner-section .slick-dots {
    display: none !important;
  }
  .team-right .cmn-sub-title {
    font-size: var(--fs-24);
    text-align: left;
  }
  .cmn-text {
    font-size: var(--fs-16);
  }

  .cmp-intro .video-text {
    font-size: var(--fs-18);
  }
  .brochure-text {
    font-size: var(--fs-18);
  }
  .sm-dec {
    padding: 16px 0;
  }
  .sm-points img {
    width: 20px;
  }
  .read-more-link {
    margin: 0 auto;
    padding: 10px;
    max-width: 160px;
    font-size: var(--fs-16);
  }
  .since-box {
    max-width: 150px;
  }
  .since-number {
    font-size: 40px;
    line-height: 40px;
  }
  .since-text {
    margin-left: 4px;
    font-size: 24px;
  }
  .award-prev-btn {
    width: 40px;
    height: 40px;
  }
  .award-next-btn {
    width: 40px;
    height: 40px;
  }
  .project-bio {
    left: 0;
  }
  .cta-btn {
    max-width: unset;
    width: 100%;
  }
  .project-title {
    font-size: var(--fs-22);
  }
  .footer-dec {
    padding: 0 0 15px 0;
  }
  .site-footer .cmn-text {
    font-size: var(--fs-16);
  }
  .contactus-sec .cmn-text {
    font-size: var(--fs-16);
  }
  .client-sec .client-title {
    padding: 0 0 30px 0;
  }
  .award-text {
    padding: 20px 0 0 0;
  }
  .award-box {
    margin: unset !important;
  }
  table th {
    font-size: 14px;
  }
  table td {
    font-size: 14px;
  }
  table img {
    width: 20px;
  }
  .grid-wrapper .wide {
    grid-row: span 1;
    grid-column: span 2;
  }
  .grid-wrapper .big {
    grid-row: span 1;
    grid-column: span 2;
  }
  .grid-wrapper .tall {
    grid-row: span 1;
    grid-column: span 2;
  }
  .gallery-box {
    grid-row: span 1;
    grid-column: span 2;
  }

  .about-banner .cmn-banner-text {
    letter-spacing: -0.6px;
  }
  .project-banner .cmn-banner-text {
    letter-spacing: 0.6px;
  }

  .careers-banner .cmn-banner-text {
    letter-spacing: 5.2px;
  }
  .vendorcode-search {
    flex-direction: column;
  }
  .exvndorcode {
    max-width: unset;
  }
  .vsearch-btn {
    max-width: unset;
  }
  .bod-img {
    width: 33%;
    height: 180px;
  }
}

@media (max-width: 480px) {
  .projectslider-box {
    height: 300px;
  }

  .timeline-row {
    margin-right: 0px;
    margin-left: 0px;
  }

  .timeline-horz-card-wrapper {
    max-height: 200px;
  }

  .client-next-btn,
  .client-prev-btn {
    width: 30px;
  }

  .imp-links > div > div > a {
    height: fit-content;
  }

  .footer-right {
    text-align: left;
    /* padding-left: 0px;
    padding-right: 0px; */
  }

  .footer-btm .cmn-text {
    text-align: left;
  }
}

/* @media only screen and (max-width:1024px){
    .video-content img{
        width: 40px;
    }
    .video-content{
        left: -15%;
    }
    .brochure-content img{
        width: 40px;
    }
    .cmp-intro .brochure-text{
        padding: 10px 0 0 0;
    }
    .project-sub-title{
        font-size: var(--fs-20);
    }
    .status{
        font-size: var(--fs-16);
        padding: 8px 10px;
    }
}  */
