@font-face {
    font-family: 'LatoBold';
    src: url('../fonts/Lato-Bold.eot');
    src: url('../fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Lato-Bold.woff2') format('woff2'),
        url('../fonts/Lato-Bold.woff') format('woff'),
        url('../fonts/Lato-Bold.ttf') format('truetype'),
        url('../fonts/Lato-Bold.svg#Lato-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'LatoBoldItalic';
    src: url('../fonts/Lato-BoldItalic.eot');
    src: url('../fonts/Lato-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Lato-BoldItalic.woff2') format('woff2'),
        url('../fonts/Lato-BoldItalic.woff') format('woff'),
        url('../fonts/Lato-BoldItalic.ttf') format('truetype'),
        url('../fonts/Lato-BoldItalic.svg#Lato-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'LatoItalic';
    src: url('../fonts/Lato-Italic.eot');
    src: url('../fonts/Lato-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Lato-Italic.woff2') format('woff2'),
        url('../fonts/Lato-Italic.woff') format('woff'),
        url('../fonts/Lato-Italic.ttf') format('truetype'),
        url('../fonts/Lato-Italic.svg#Lato-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'LatoBlackItalic';
    src: url('../fonts/Lato-BlackItalic.eot');
    src: url('../fonts/Lato-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Lato-BlackItalic.woff2') format('woff2'),
        url('../fonts/Lato-BlackItalic.woff') format('woff'),
        url('../fonts/Lato-BlackItalic.ttf') format('truetype'),
        url('../fonts/Lato-BlackItalic.svg#Lato-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'LatoBlack';
    src: url('../fonts/Lato-Black.eot');
    src: url('../fonts/Lato-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Lato-Black.woff2') format('woff2'),
        url('../fonts/Lato-Black.woff') format('woff'),
        url('../fonts/Lato-Black.ttf') format('truetype'),
        url('../fonts/Lato-Black.svg#Lato-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'LatoMedium';
    src: url('../fonts/Lato-Medium.eot');
    src: url('../fonts/Lato-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Lato-Medium.woff2') format('woff2'),
        url('../fonts/Lato-Medium.woff') format('woff'),
        url('../fonts/Lato-Medium.ttf') format('truetype'),
        url('../fonts/Lato-Medium.svg#Lato-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'LatoSemiboldItalic';
    src: url('../fonts/Lato-SemiboldItalic.eot');
    src: url('../fonts/Lato-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Lato-SemiboldItalic.woff2') format('woff2'),
        url('../fonts/Lato-SemiboldItalic.woff') format('woff'),
        url('../fonts/Lato-SemiboldItalic.ttf') format('truetype'),
        url('../fonts/Lato-SemiboldItalic.svg#Lato-SemiboldItalic') format('svg');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'LatoLightItalic';
    src: url('../fonts/Lato-LightItalic.eot');
    src: url('../fonts/Lato-LightItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Lato-LightItalic.woff2') format('woff2'),
        url('../fonts/Lato-LightItalic.woff') format('woff'),
        url('../fonts/Lato-LightItalic.ttf') format('truetype'),
        url('../fonts/Lato-LightItalic.svg#Lato-LightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'LatoLight';
    src: url('../fonts/Lato-Light.eot');
    src: url('../fonts/Lato-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Lato-Light.woff2') format('woff2'),
        url('../fonts/Lato-Light.woff') format('woff'),
        url('../fonts/Lato-Light.ttf') format('truetype'),
        url('../fonts/Lato-Light.svg#Lato-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'LatoMediumItalic';
    src: url('../fonts/Lato-MediumItalic.eot');
    src: url('../fonts/Lato-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Lato-MediumItalic.woff2') format('woff2'),
        url('../fonts/Lato-MediumItalic.woff') format('woff'),
        url('../fonts/Lato-MediumItalic.ttf') format('truetype'),
        url('../fonts/Lato-MediumItalic.svg#Lato-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'LatoRegular';
    src: url('../fonts/Lato-Regular.eot');
    src: url('../fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Lato-Regular.woff2') format('woff2'),
        url('../fonts/Lato-Regular.woff') format('woff'),
        url('../fonts/Lato-Regular.ttf') format('truetype'),
        url('../fonts/Lato-Regular.svg#Lato-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'LatoSemibold';
    src: url('../fonts/Lato-Semibold.eot');
    src: url('../fonts/Lato-Semibold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Lato-Semibold.woff2') format('woff2'),
        url('../fonts/Lato-Semibold.woff') format('woff'),
        url('../fonts/Lato-Semibold.ttf') format('truetype'),
        url('../fonts/Lato-Semibold.svg#Lato-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}


