@import url(../css/fonts.css);
:root{
    --primary: #3f4041;
    --secondary: #188ac6;
    --green:#4ac966;
    --white:#fff;
    --black:#1a1a19;
    --off-white:#f7f9fe;
    --off-gray:#959595;


    --gray-text: #515151;
    --sub-title-text: rgba(26, 26, 25, 0.2);


    
     --font-latoblack: 'LatoBlack';
     --font-bold: 'LatoBold';
     --font-semibold: 'LatoSemibold';
     --font-regular:'LatoRegular';
     --font-light:'LatoLight';
     --font-medium:'LatoMedium';



    --fs-90:90px;
    --fs-70:70px;
    --fs-60:60px;
    --fs-56:56px;
    --fs-50:50px;
    --fs-46:46px;
    --fs-44:44px;
    --fs-40:40px;
    --fs-34:34px;
    --fs-36:36px;
    --fs-30:30px;
    --fs-32:32px;
    --fs-28:28px;
    --fs-26:26px;
    --fs-24:24px;
    --fs-22:22px;
    --fs-20:20px;
    --fs-18:18px;
    --fs-16:16px;
    --fs-14:14px;
    --fs-12:12px;

}